import React from 'react'

export default function BlockItem({content, shape, overlay}) {
  return (
      <div for={content.name} className='block-item'>
        <div className='block-img'>
          <div id={content.id} className='overlay disabled'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
          </div>
          <img style={shape === 'round' ? {borderRadius: '100%'} : {borderRadius: '0%'}} src={content.previewImg} alt={'image of ' + content.name} />
        </div>
        <h3 className='block-title'>{content.name}</h3>
      </div>
  )
}
