import { createContext, useState } from "react";
import { getFirstPart } from "../functions/stringSplitting";

export const selectedArtistsContext = createContext();

const SelectedArtistsList = (props) => {
  const [artist, setArtist] = useState([]);

  const handleArtistChange = (id) => {
    if (artist.length <= 4){
        if (!artist.includes(id) && document.getElementById(getFirstPart(id, ':')).classList.contains("disabled")) {
          setArtist([...artist,id])
          document.getElementById(getFirstPart(id, ':')).classList.remove("disabled");
        
      } else if (artist.includes(id)) {
        setArtist(artist.filter(artist => artist !== id))
        document.getElementById(getFirstPart(id, ':')).classList.add("disabled");
      }
    } else if (artist.length === 5) {
      if (artist.includes(id)) {
        setArtist(artist.filter(artist => artist !== id))
        document.getElementById(getFirstPart(id, ':')).classList.add("disabled");
      }
    }
  }

  const handleArtistChange2 = (name) => {
    if (artist.length <= 4){
      for (let i = 0; i < name.length; i++) {
        if (!artist.includes(name[i])) {
          setArtist([...artist,name[i]])
        }
      }
    }
  }

  const handleArtistRemoved = (name) => {
    if (artist.includes(name)) {
      setArtist(artist.filter(artist => artist !== name))
    }
  }

  const value = {
    artist,
    handleArtistChange,
    handleArtistChange2,
    handleArtistRemoved
  }

  return(
    <selectedArtistsContext.Provider value={value}>
      {props.children}
    </selectedArtistsContext.Provider>
  )
}

export default SelectedArtistsList;
