import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { composeWithDevTools } from 'redux-devtools-extension';
import './index.scss';
import App from './App';
import Createposter from './pages/Createposter';


import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import SelectedArtistsList from './context/SelectedArtistsList';

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
// };

export const firebaseConfig = {
  apiKey: 'AIzaSyDzp5GOjaGLD1LqN0vjSdSqebXbWLOfexk',
  authDomain: 'pp-poster-generator.firebaseapp.com',
  projectId:'pp-poster-generator',
  storageBucket:'pp-poster-generator.appspot.com',
  messagingSenderId: '531280055446',
  appId: '1:531280055446:web:bae446ad939ddb067f46b7',
};

// init Firebase App
firebase.initializeApp(firebaseConfig);

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: composeWithDevTools(applyMiddleware(thunk))}
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
            <SelectedArtistsList>
              <Routes>
                <Route index element={<App />} />
                <Route path='create-poster' element={<Createposter />} />
              </Routes>
            </SelectedArtistsList>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>
);
